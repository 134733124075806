<template>
    <div class="uk-container uk-container-expand uk-padding-small card-scrollable">
        <!-- Loader -->
        <div v-if="is_loading" class="uk-flex uk-flex-center uk-flex-middle" style="min-height: 100%;">
            <div uk-spinner="ratio: 2"></div>
        </div>
        <!-- End loader -->

        <!-- Page content -->
        <div v-else-if="!is_loading" class="uk-card uk-card-default uk-padding-small">
            <!-- Worker detail -->
            <div class="uk-margin-top" uk-grid>
                <div class="uk-width-1-6 uk-flex uk-flex-middle uk-flex-center">
                    <div class="user-photo">
                        <img 
                            v-if="user_data && user_data.photo_url && user_data.photo_url.file_url" 
                            :src="user_data.photo_url.file_url"
                            class="uk-margin-small-top uk-margin-remove-top"
                            @error="(event) => imgError({ event })" 
                        >
                        <img 
                            v-else
                            :src="`${images}/avatar.svg`" 
                            alt="User photo"
                        >
                    </div>
                </div>
                <div class="uk-width-expand">
                    <div class="uk-flex uk-flex-middle uk-flex-between">
                        <p class="uk-margin-remove uk-padding-remove uk-text-lead uk-text-bold">{{ user_data && user_data.fullname ? user_data.fullname : '-' }}</p>
                        <button 
                            v-if="user_data"
                            type="button" 
                            style="background: transparent; outline: none; border: none; cursor: pointer;"
                            @click="editUser({ worker: user_data })"
                        >
                            <img :src="`${images}/pencil.svg`" alt="Pencil icon" style="width:20px">
                        </button>
                    </div>
                    <div class="uk-child-width-1-3" uk-grid>
                        <div>
                            <p class="uk-margin-remove uk-padding-remove">Email: {{ user_data && user_data.email ? user_data.email : '-' }}</p>
                            <p class="uk-margin-remove uk-padding-remove">Phone: {{ user_data && user_data.phone_number ? user_data.phone_number : '-' }}</p>
                            <p class="uk-margin-remove uk-padding-remove">
                                DoB: {{ user_data ? dobUser({ user: user_data }) : '-' }}
                            </p>
                        </div>
                        <div>
                            <p class="uk-margin-remove uk-padding-remove">Address: {{ user_data && user_data.address ? user_data.address : '-' }}</p>
                            <p class="uk-margin-remove uk-padding-remove">Role: {{ user_data ? user_data.roles === 'zxc123' ?  "Candidate" : "Employer" : '-' }}</p>
                            <p class="uk-margin-remove uk-padding-remove">Partner Status: {{ user_data ? user_data.is_partner ? "Partner" : "Not Partner" : '-' }}</p>
                        </div>
                        <div>
                            <p class="uk-margin-remove uk-padding-remove">Verification Code: {{ user_data && user_data.verification_code ? user_data.verification_code : '-' }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End worker detail -->

            <!-- About me -->
            <div class="uk-margin-top">
                <p class="uk-margin-remove uk-padding-remove uk-text-lead uk-text-bold">About Me</p>
                <p class="uk-margin-remove uk-padding-remove">{{ user_data && user_data.description ? user_data.description : '-' }}</p>
            </div>
            <!-- End about me -->
            
            <!-- Job preferences -->
            <div class="uk-margin-top">
                <p class="uk-margin-remove uk-padding-remove uk-text-lead uk-text-bold">Job Preferences</p>
                <p class="uk-margin-remove uk-padding-remove">
                    {{ user_data && user_data.job_preference_ids.length > 0 ? showJobPref({ data: user_data.job_preference_ids }) : '-' }}
                </p>
            </div>
            <!-- End job preferences -->

            <!-- Work experience -->
            <div class="uk-margin-top" uk-grid>
                <div class="uk-width-1-1 uk-margin-remove uk-flex uk-flex-middle uk-flex-between">
                    <p class="uk-margin-remove uk-padding-remove uk-text-lead uk-text-bold">Work Experiences</p>
                    <button type="button" class="uk-button uk-button-primary uk-border-rounded" @click="addUserExperience">Add</button>
                </div>
                <div v-if="user_exp && user_exp.length > 0" class="uk-width-1-1 uk-margin-remove">
                    <div 
                        v-for="(experience, index) in user_exp"
                        :key="index" 
                        class="uk-margin-small-top uk-padding-remove uk-text-left" 
                        uk-grid
                    >
                        <div class="uk-width-1-4 uk-text-bold">
                            <span v-if="experience.start_date !== null">{{ getYear({ date: experience.start_date }) }}</span>
                            <span v-if="experience.start_date !== null && (experience.present || experience.end_date !== null)"> - </span>
                            <span v-if="experience.present && experience.end_date === null">Now</span>
                            <span v-else-if="!experience.present && experience.end_date !== null">{{ getYear({ date: experience.end_date }) }}</span>
                        </div>
                        <div class="uk-width-1-4">
                            <p class="uk-margin-remove uk-padding-remove uk-text-bold">{{ experience.company_name || '-' }}</p>
                            <p class="uk-margin-remove uk-padding-remove">{{ experience.title || '-' }}</p>
                        </div>
                        <div class="uk-width-1-4">
                            <p class="uk-margin-remove uk-padding-remove">{{ experience.description || '-' }}</p>
                        </div>
                        <div class="uk-width-1-4 uk-flex uk-flex-right">
                            <button type="button" class="uk-button uk-button-default uk-border-rounded" @click="editUserExperience({ experience })">Edit</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End work experiences -->

            <!-- Educations -->
            <div class="uk-margin-top" uk-grid>
                <div class="uk-width-1-1 uk-margin-remove uk-flex uk-flex-middle uk-flex-between">
                    <p class="uk-margin-remove uk-padding-remove uk-text-lead uk-text-bold">Educations</p>
                    <button type="button" class="uk-button uk-button-primary uk-border-rounded" @click="addUserEducation">Add</button>
                </div>
                <div v-if="user_edu !== null && user_edu.length > 0" class="uk-width-1-1 uk-margin-remove">
                    <div 
                        v-for="(education, index) in user_edu"
                        :key="index"
                        class="uk-margin-small-top uk-padding-remove uk-text-left" 
                        uk-grid
                    >
                        <div class="uk-width-1-4 uk-text-bold">
                            <span v-if="education.start_date !== null">{{ getYear({ date: education.start_date }) }}</span>
                            <span v-if="education.start_date !== null && (education.present || education.end_date !== null)"> - </span>
                            <span v-if="education.present && education.end_date === null">Now</span>
                            <span v-else-if="!education.present && education.end_date !== null">{{ getYear({ date: education.end_date }) }}</span>
                        </div>
                        <div class="uk-width-1-4">
                            <p class="uk-margin-remove uk-padding-remove uk-text-bold">{{ education.institution_name || '-' }}</p>
                        </div>
                        <div class="uk-width-1-4">
                            <p class="uk-margin-remove uk-padding-remove">{{ education.majors || '-' }}</p>
                        </div>
                        <div class="uk-width-1-4 uk-flex uk-flex-right">
                            <button type="button" class="uk-button uk-button-default uk-border-rounded" @click="editUserEducation({ education })">Edit</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End educations -->

            <!-- Skills -->
            <div class="uk-margin-top" uk-grid>
                <div class="uk-width-1-1 uk-margin-remove uk-flex uk-flex-middle uk-flex-between">
                    <p class="uk-margin-remove uk-padding-remove uk-text-lead uk-text-bold">Skills</p>
                    <button type="button" class="uk-button uk-button-primary uk-border-rounded" @click="addUserSkill">Add</button>
                </div>
                <div v-if="user_skill !== null && user_skill.length > 0" class="uk-width-1-1 uk-margin-remove">
                    <div class="uk-margin-small-top uk-padding-remove uk-text-left" uk-grid>
                        <div class="uk-width-1-1">
                            <p class="uk-margin-remove uk-padding-remove">{{ skillList({ skills: user_skill }) }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End skills -->

            <!-- Requirement documents -->
            <div class="uk-margin-top" uk-grid>
                <div class="uk-width-1-1 uk-margin-remove uk-flex uk-flex-middle uk-flex-between">
                    <p class="uk-margin-remove uk-padding-remove uk-text-lead uk-text-bold">Required Documents</p>
                    <button 
                        type="button" 
                        class="uk-button uk-button-primary uk-border-rounded" 
                        @click="addUserAdditionalDocument"
                    >Add</button>
                </div>
                <div v-if="user_req_doc.length > 0" class="uk-width-1-1 uk-margin-remove uk-padding-remove uk-text-left" uk-grid>
                    <div class="uk-flex uk-flex-column">
                        <a 
                            v-for="(doc, index) in user_req_doc" 
                            :key="index"
                            :href="doc.url.file_url"
                            class="uk-text-bold uk-margin-small-top"
                        >{{ doc.name || '-' }}</a>
                    </div>
                </div>
            </div>
            <!-- End requirement documents -->

            <!-- Additional documents -->
            <div class="uk-margin-top" uk-grid>
                <div class="uk-width-1-1 uk-margin-remove uk-flex uk-flex-middle uk-flex-between">
                    <p class="uk-margin-remove uk-padding-remove uk-text-lead uk-text-bold">Additional Documents</p>
                    <button 
                        v-if="button_add_other_document_loading" 
                        class="uk-button uk-button-default uk-border-rounded" 
                        disabled
                    >
                        <span><div uk-spinner="ratio: 0.5"></div></span>
                        <span class="uk-margin-small-left">Loading</span>
                    </button>
                    <div v-else uk-form-custom @input="uploadOtherDocument">
                        <input 
                            name="other_document"
                            type="file"
                            ref="other_document"
                        >
                        <button
                            type="button" 
                            class="uk-button uk-button-primary uk-border-rounded" 
                            tabindex="-1"
                        >Add</button>
                    </div>
                </div>
                <div v-if="user_doc.length > 0" class="uk-width-1-1 uk-margin-remove uk-padding-remove uk-text-left" uk-grid>
                    <div class="uk-flex uk-flex-column">
                        <a 
                            v-for="(doc, index) in user_doc" :key="index"
                            class="uk-text-bold uk-margin-small-top"
                            @click="deleteDocument({ document: doc })" 
                        >{{ doc.name || '-' }}</a>
                    </div>
                </div>
            </div>
            <!-- End additional documents -->

            <!-- Download CV -->
            <div v-if="user_data" class="uk-margin-top uk-margin-bottom uk-text-center">
                <button 
                    v-if="button_unduh_cv_loading" 
                    class="uk-button uk-button-default uk-border-rounded" 
                    disabled
                >
                    <span><div uk-spinner="ratio: 0.5"></div></span>
                    <span class="uk-margin-small-left">Loading</span>
                </button>
                <button 
                    v-else
                    class="uk-button uk-button-primary uk-border-rounded" 
                    :disabled="!user_data.cv" 
                    @click="downloadCV"
                >Download CV</button>
            </div>
            <!-- End download CV -->

            <!-- Form user modal -->
            <modal name="form-user-modal" height="auto" styles="max-height: 90%; overflow-y: auto;" :clickToClose="false">
                <button class="uk-modal-close-default" type="button" uk-close @click="$modal.hide('form-user-modal')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">User Form</h2>
                </div>
                <form @submit.prevent="saveUser">
                    <div class="uk-grid-small uk-padding" uk-grid>
                        <div class="uk-width-1-1">
                            <label for="fullname" class="uk-form-label">Full Name <span class="uk-text-danger">*</span></label>
                            <input 
                                v-model="form_user_modal.fullname"
                                id="fullname"
                                name="Fullname" 
                                type="text"
                                class="uk-input uk-border-rounded"
                                :class="{'uk-form-danger': errors.has('Fullname')}"
                                v-validate="'required'"
                                required
                            >
                            <span class="uk-text-small uk-text-danger" v-show="errors.has('Fullname')">{{ errors.first('Fullname') }}</span>
                        </div>
                        <div class="uk-width-1-1">
                            <label for="login_via" class="uk-form-label">Login via: </label>
                            <label class="uk-form-label uk-margin-small-right">
                                <input
                                    v-model="form_user_modal.login_via_email"
                                    id="login_via_email"
                                    name="login_via_email"
                                    class="uk-radio"
                                    type="radio"
                                    v-bind:value="true"
                                > Email
                            </label>
                            <label class="uk-form-label uk-margin-small-right">
                                <input
                                    v-model="form_user_modal.login_via_email"
                                    id="login_via_password"
                                    name="login_via_email"
                                    class="uk-radio"
                                    type="radio"
                                    v-bind:value="false"
                                > Phone number
                            </label>
                        </div>
                        <div class="uk-width-1-1">
                            <label for="email" class="uk-form-label">Email <span v-if="form_user_modal.login_via_email" class="uk-text-danger">*</span></label>
                            <input 
                                v-model="form_user_modal.email"
                                id="email"
                                name="Email" 
                                type="email"
                                class="uk-input uk-border-rounded"
                                autocomplete="off"
                                :class="{'uk-form-danger': errors.has('Email')}"
                                v-validate="{required: form_user_modal.login_via_email}"
                                :required="form_user_modal.login_via_email"
                            >
                            <span class="uk-text-small uk-text-danger" v-show="errors.has('Email')">
                                {{ errors.first('Email') }} Switch login mode to make this field optional.
                            </span>
                        </div>
                        <div class="uk-width-1-1">
                            <label for="phone_number" class="uk-form-label">Phone Number <span v-if="!form_user_modal.login_via_email" class="uk-text-danger">*</span></label>
                            <input 
                                v-model="form_user_modal.phone_number"
                                id="phone_number"
                                name="Phone Number" 
                                type="text"
                                class="uk-input uk-border-rounded"
                                autocomplete="off"
                                :class="{'uk-form-danger': errors.has('Phone Number')}"
                                v-validate="{required: !form_user_modal.login_via_email}"
                                :required="!form_user_modal.login_via_email"
                            >
                            <span class="uk-text-small uk-text-danger" v-show="errors.has('Phone Number')">
                                {{ errors.first('Phone Number') }} Switch login mode to make this field optional.
                            </span>
                        </div>
                        <div class="uk-width-1-1">
                            <label for="password" class="uk-form-label"><span v-if="form_is_edit">Change </span>Password</label>
                            <input 
                                v-model="form_user_modal.password"
                                id="password"
                                name="Password" 
                                type="password"
                                class="uk-input uk-border-rounded"
                                autocomplete="off"
                                :class="{'uk-form-danger': errors.has('Password')}"
                                v-validate="{required: !form_is_edit}"
                            >
                            <span v-if="!form_is_edit" class="uk-text-small uk-text-danger" v-show="errors.has('Password')">{{ errors.first('Password') }}</span>
                            <p class="uk-text-small uk-margin-remove-bottom">Password must consists of:</p>
                            <ul class="uk-margin-remove-top">
                                <li>
                                    <p 
                                    class="uk-width-1-1 uk-flex-center uk-text-small uk-margin-remove"
                                    >
                                    {{ 'Minimum 8 characters' }}
                                        <img v-if="passwordErrors.minimalChars" :src="`${images}/check-circle.svg`" alt="" width="15" height="15">
                                        <img v-else :src="`${images}/alert_red.svg`" alt="" width="15" height="15">
                                    </p>
                                </li>
                                <li>
                                    <p class="uk-width-1-1 uk-flex-center uk-text-small uk-margin-remove">
                                    {{ '1 uppercase letter (A-Z)' }}
                                        <img v-if="passwordErrors.hasUpperCase" :src="`${images}/check-circle.svg`" alt="" width="15" height="15">
                                        <img v-else :src="`${images}/alert_red.svg`" alt="" width="15" height="15">
                                    </p>
                                </li>
                                <li>
                                    <p class="uk-width-1-1 uk-flex-center uk-text-small uk-margin-remove">
                                    {{ '1 lowercase letter (a-z)' }}
                                        <img v-if="passwordErrors.hasLowerCase" :src="`${images}/check-circle.svg`" alt="" width="15" height="15">
                                        <img v-else :src="`${images}/alert_red.svg`" alt="" width="15" height="15">
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <div class="uk-width-1-1">
                            <label for="birthplace" class="uk-form-label">Birthplace <span class="uk-text-danger">*</span></label>
                            <multiselect
                                v-model="form_user_modal.birthplace_city_id"
                                id="birthplace"
                                name="Birthplace"
                                label="name"
                                placeholder="Type to search city name"
                                track-by="_id"
                                :options="city_options"
                                :multiple="false"
                                :close-on-select="true"
                                :searchable="true"
                                :loading='is_fetching'
                                :internal-search="false"
                                :options-limit="50"
                                @search-change="setCityValues"
                                v-validate="'required'"
                                required
                            ></multiselect>
                            <span class="uk-text-small uk-text-danger" v-show="errors.has('Birthplace')">{{ errors.first('Birthplace') }}</span>
                        </div>
                        <div class="uk-width-1-1">
                            <label for="birthdate" class="uk-form-label">Birthdate <span class="uk-text-danger">*</span></label>
                            <datetime
                                v-model="form_user_modal.birthdate"
                                id="birthdate"
                                name="Birthdate"
                                input-class="uk-input uk-border-rounded"
                                :class="{'uk-form-danger': errors.has('Birthdate')}"
                                type="date"
                                placeholder="dd/mm/yy"
                                v-validate="'required'"
                                required
                            ></datetime>
                            <span class="uk-text-small uk-text-danger" v-show="errors.has('Birthdate')">{{ errors.first('Birthdate') }}</span>
                        </div>
                        <div class="uk-width-1-1">
                            <label for="city" class="uk-form-label">City <span class="uk-text-danger">*</span></label>
                            <multiselect
                                v-model="form_user_modal.domicile_city_id"
                                id="city"
                                name="City"
                                placeholder="Type to search city name"
                                :class="{'uk-form-danger': errors.has('City')}"
                                label="name"
                                track-by="_id"
                                :options="city_options"
                                :multiple="false"
                                :close-on-select="true"
                                :searchable="true"
                                :loading='is_fetching'
                                :internal-search="false"
                                :options-limit="50"
                                @search-change="setCityValues"
                                v-validate="'required'"
                                required
                            ></multiselect>
                            <span class="uk-text-small uk-text-danger" v-show="errors.has('City')">{{ errors.first('City') }}</span>
                        </div>
                        <div class="uk-width-1-1">
                            <label for="address" class="uk-form-label">Address <span class="uk-text-danger">*</span></label>
                            <input
                                v-model="form_user_modal.address"
                                id="address"
                                name="Address"
                                class="uk-input uk-border-rounded"
                                :class="{'uk-form-danger': errors.has('address')}"
                                type="text"
                                placeholder="Address"
                                v-validate="'required'"
                                required
                            >
                            <span class="uk-text-small uk-text-danger" v-show="errors.has('Address')">{{ errors.first('Address') }}</span>
                        </div>
                        <div class="uk-width-1-1">
                            <label for="about_me" class="uk-form-label">About Me</label>
                            <textarea
                                v-model="form_user_modal.description"
                                id="about_me"
                                name="About Me"
                                class="uk-textarea uk-border-rounded"
                                type="text"
                                rows="3"
                                placeholder="About Me"
                            ></textarea>
                        </div>
                        <div class="uk-width-1-1">
                            <label for="job_preferences" class="uk-form-label">Job Preferences</label>
                            <multiselect
                                v-model="form_user_modal.job_preference_ids"
                                id="job_preferences"
                                name="Job Preferences"
                                placeholder="Type to search job preference"
                                label="name"
                                track-by="_id"
                                :options="job_preference_options"
                                :multiple="true"
                                :close-on-select="false"
                                :searchable="true"
                                :loading='is_fetching'
                                :internal-search="true"
                                :options-limit="10"
                            ></multiselect>
                        </div>
                        <div v-show="false" class="uk-width-1-1">
                            <label for="role" class="uk-form-label">Role <span class="uk-text-danger">*</span></label>
                            <multiselect
                                v-model="form_user_modal.roles"
                                id="role"
                                name="Role"
                                placeholder="Select Role"
                                label="name"
                                track-by="name"
                                :options="role_options"
                                :class="{'uk-form-danger': errors.has('Role')}"
                                v-validate="'required'"
                                required
                            ></multiselect>
                            <span class="uk-text-small uk-text-danger" v-show="errors.has('Role')">{{ errors.first('Role') }}</span>
                        </div>
                        <div v-show="false" v-if="form_user_modal.roles && form_user_modal.roles.name === 'Candidate'" class="uk-width-1-1">
                            <label for="partners" class="uk-form-label">Partners</label>
                            <multiselect
                                v-model="form_user_modal.partner_ids"
                                id="partners"
                                name="Partners"
                                placeholder="Type to search company partner"
                                label="name"
                                track-by="name"
                                :options="partner_options"
                                :multiple="true"
                                :close-on-select="false"
                                :searchable="true"
                                :loading='is_fetching'
                                :internal-search="false"
                                :options-limit="50"
                                @search-change="setPartnerValues"
                            ></multiselect>
                        </div>
                        <div v-if="form_is_edit" class="uk-width-1-1">
                            <label for="verification_code" class="uk-form-label">Verification Code <span class="uk-text-danger">*</span></label>
                            <input
                                v-model="form_user_modal.verification_code"
                                id="verification_code"
                                name="Verification Code"
                                class="uk-input uk-border-rounded"
                                :class="{'uk-form-danger': errors.has('Verification Code')}"
                                type="text"
                                placeholder="Verification Code"
                                v-validate="'required'"
                                required
                            >
                            <span class="uk-text-small uk-text-danger" v-show="errors.has('Verification Code')">{{ errors.first('Verification Code') }}</span>
                        </div>
                        <div class="uk-width-1-1">
                            <label for="notes" class="uk-form-label">Notes</label>
                            <input
                                v-model="form_user_modal.notes_superadmin"
                                id="notes"
                                name="Notes"
                                class="uk-input uk-border-rounded"
                                type="text"
                                placeholder="Notes"
                            >
                        </div>
                        <div class="uk-width-1-1">
                            <label for="user_verified" class="uk-form-label">
                                <input
                                    v-model="form_user_modal.user_verified"
                                    id="user_verified"
                                    name="User Verified"
                                    class="uk-checkbox uk-margin-small-right uk-border-rounded"
                                    type="checkbox"
                                > User Verified
                            </label>
                        </div>
                    </div>
                    <div class="uk-modal-footer uk-text-right">
                        <button 
                            v-if="form_user_modal_button_loading" 
                            class="uk-button uk-button-default" 
                            disabled
                        >
                            <span><div uk-spinner="ratio: 0.5"></div></span>
                            <span class="uk-margin-small-left">Loading</span>
                        </button>
                        <button v-else class="uk-button uk-button-primary" type="submit">Save</button>
                    </div>
                </form>
            </modal>
            <!-- End form user modal -->

            <!-- Form user experience modal -->
            <modal name="form-user-experience-modal" height="auto" styles="max-height: 90%; overflow-y: auto;" :clickToClose="false">
                <button class="uk-modal-close-default" type="button" uk-close @click="$modal.hide('form-user-experience-modal')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Work Experience</h2>
                </div>
                <form @submit.prevent="saveUserExperience">
                    <div class="uk-grid-small uk-padding" uk-grid>
                        <div class="uk-width-1-1">
                            <label for="company_name" class="uk-form-label">Company <span class="uk-text-danger">*</span></label>
                            <input 
                                v-model="form_user_experience_modal.company_name"
                                id="company_name"
                                name="company_name" 
                                type="text" 
                                class="uk-input uk-border-rounded"
                                :class="{'uk-form-danger': errors.has('form_user_experience_modal.company_name')}"
                                v-validate="'required'"
                                data-vv-rules="required"
                                data-vv-scope="form_user_experience_modal"
                                required
                            >
                            <span class="uk-text-small uk-text-danger" v-show="errors.has('form_user_experience_modal.company_name')">
                                {{ errors.first('form_user_experience_modal.company_name') }}
                            </span>
                        </div>
                        <div class="uk-width-1-1">
                            <label for="title" class="uk-form-label">Job Title <span class="uk-text-danger">*</span></label>
                            <input 
                                v-model="form_user_experience_modal.title"
                                id="title"
                                name="title" 
                                type="text" 
                                class="uk-input uk-border-rounded"
                                :class="{'uk-form-danger': errors.has('form_user_experience_modal.title')}"
                                v-validate="'required'"
                                data-vv-rules="required"
                                data-vv-scope="form_user_experience_modal"
                                required
                            >
                            <span class="uk-text-small uk-text-danger" v-show="errors.has('form_user_experience_modal.title')">
                                {{ errors.first('form_user_experience_modal.title') }}
                            </span>
                        </div>
                        <div class="uk-width-1-2">
                            <label for="start_date" class="uk-form-label">Start Date <span class="uk-text-danger">*</span></label>
                            <div class="uk-inline uk-width-expand">
                                <datetime
                                    v-model="form_user_experience_modal.start_date"
                                    id="start_date"
                                    name="start_date"
                                    input-class="uk-input uk-border-rounded"
                                    type="date"
                                    v-validate="'required'"
                                    data-vv-rules="required"
                                    data-vv-scope="form_user_experience_modal"
                                    required
                                ></datetime>
                                <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: calendar"></span>
                            </div>
                            <span class="uk-text-small uk-text-danger" v-show="errors.has('form_user_experience_modal.start_date')">
                                {{ errors.first('form_user_experience_modal.start_date') }}
                            </span>
                        </div>
                        <div class="uk-width-1-2">
                            <label for="end_date" class="uk-form-label">End Date</label>
                            <div class="uk-inline uk-width-expand">
                                <datetime
                                    v-model="form_user_experience_modal.end_date"
                                    id="end_date"
                                    name="end_date"
                                    input-class="uk-input uk-border-rounded"
                                    type="date"
                                    :min-datetime="form_user_experience_modal.start_date"
                                ></datetime>
                                <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: calendar"></span>
                            </div>
                        </div>
                        <div class="uk-width-1-1">
                            <label for="job_description" class="uk-form-label">Job Description</label>
                            <textarea 
                                id="job_description"
                                name="job_description" 
                                v-model="form_user_experience_modal.description"
                                rows="5"
                                :maxlength="300"
                                class="uk-textarea uk-border-rounded"
                            ></textarea>
                        </div>
                    </div>
                    <div
                        class="uk-modal-footer"
                        :class="[form_is_edit ? 'uk-flex uk-flex-between uk-flex-middle' : 'uk-text-right']"
                    >
                        <div v-if="form_is_edit">
                            <button 
                                v-if="form_user_experience_button_delete_loading" 
                                class="uk-button uk-button-default uk-margin-small-left" 
                                disabled
                            >
                                <span><div uk-spinner="ratio: 0.5"></div></span>
                                <span class="uk-margin-small-left">Loading</span>
                            </button>
                            <button 
                                v-else
                                class="uk-button uk-button-danger" 
                                type="button"
                                :disabled="form_user_experience_button_save_loading"
                                @click="deleteUserExperience"
                            >Delete</button>
                        </div>
                        <div>
                            <button 
                                v-if="form_user_experience_button_save_loading" 
                                class="uk-button uk-button-default uk-margin-small-left" 
                                disabled
                            >
                                <span><div uk-spinner="ratio: 0.5"></div></span>
                                <span class="uk-margin-small-left">Loading</span>
                            </button>
                            <button 
                                v-else 
                                class="uk-button uk-button-primary uk-margin-small-left" 
                                type="submit"
                                :disabled="form_user_experience_button_delete_loading"
                            >Save</button>
                        </div>
                    </div>
                </form>
            </modal>
            <!-- End form user experience modal -->

            <!-- Form user education modal -->
            <modal name="form-user-education-modal" height="auto" styles="max-height: 90%; overflow-y: auto;" :clickToClose="false">
                <button class="uk-modal-close-default" type="button" uk-close @click="$modal.hide('form-user-education-modal')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Education</h2>
                </div>
                <form @submit.prevent="saveUserEducation">
                    <div class="uk-grid-small uk-padding" uk-grid>
                        <div class="uk-width-1-1">
                            <label for="education_level" class="uk-form-label">Education Level <span class="uk-text-danger">*</span></label>
                            <multiselect
                                v-model="form_user_education_modal.education_level_id"
                                label="name"
                                id="education_level"
                                name="education_level"
                                :class="{'uk-form-danger': errors.has('form_user_education_modal.education_level')}"
                                track-by="_id"
                                :options="educations"
                                :multiple="false"
                                :close-on-select="true"
                                :searchable="true"
                                :options-limit="50"
                                v-validate="'required'"
                                data-vv-rules="required"
                                data-vv-scope="form_user_education_modal"
                                required
                            ></multiselect>
                            <span class="uk-text-small uk-text-danger" v-show="errors.has('form_user_education_modal.education_level')">
                                {{ errors.first('form_user_education_modal.education_level') }}
                            </span>
                        </div>
                        <div class="uk-width-1-1">
                            <label for="institution_name" class="uk-form-label">Institution Name <span class="uk-text-danger">*</span></label>
                            <input
                                v-model="form_user_education_modal.institution_name"
                                id="institution_name"
                                name="institution_name" 
                                type="text"
                                class="uk-input uk-border-rounded"
                                :class="{'uk-form-danger': errors.has('form_user_education_modal.institution_name')}"
                                v-validate="'required'"
                                data-vv-rules="required"
                                data-vv-scope="form_user_education_modal"
                                required
                            >
                            <span class="uk-text-small uk-text-danger" v-show="errors.has('form_user_education_modal.institution_name')">
                                {{ errors.first('form_user_education_modal.institution_name') }}
                            </span>
                        </div>
                        <div class="uk-width-1-1">
                            <label for="majors" class="uk-form-label">Majors <span class="uk-text-danger">*</span></label>
                            <input
                                v-model="form_user_education_modal.majors"
                                id="majors"
                                name="majors" 
                                type="text"
                                class="uk-input uk-border-rounded"
                                :class="{'uk-form-danger': errors.has('form_user_education_modal.majors')}"
                                v-validate="'required'"
                                data-vv-rules="required"
                                data-vv-scope="form_user_education_modal"
                                required
                            >
                        </div>
                        <div class="uk-width-1-2">
                            <label for="start_date" class="uk-form-label">Start Date <span class="uk-text-danger">*</span></label>
                            <div class="uk-inline uk-width-expand">
                                <datetime
                                    v-model="form_user_education_modal.start_date"
                                    id="start_date"
                                    name="start_date"
                                    input-class="uk-input uk-border-rounded"
                                    type="date"
                                    v-validate="'required'"
                                    data-vv-rules="required"
                                    data-vv-scope="form_user_education_modal"
                                    required
                                ></datetime>
                                <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: calendar"></span>
                            </div>
                            <span class="uk-text-small uk-text-danger" v-show="errors.has('form_user_education_modal.start_date')">
                                {{ errors.first('form_user_education_modal.start_date') }}
                            </span>
                        </div>
                        <div class="uk-width-1-2">
                            <label for="end_date" class="uk-form-label">End Date</label>
                            <div class="uk-inline uk-width-expand">
                                <datetime
                                    v-model="form_user_education_modal.end_date"
                                    id="end_date"
                                    name="end_date"
                                    input-class="uk-input uk-border-rounded"
                                    type="date"
                                ></datetime>
                                <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: calendar"></span>
                            </div>
                        </div>
                    </div>
                    <div 
                        class="uk-modal-footer"
                        :class="[form_is_edit ? 'uk-flex uk-flex-between uk-flex-middle' : 'uk-text-right']"
                    >
                        <div v-if="form_is_edit">
                            <button 
                                v-if="form_user_education_button_delete_loading" 
                                class="uk-button uk-button-default uk-margin-small-left" 
                                disabled
                            >
                                <span><div uk-spinner="ratio: 0.5"></div></span>
                                <span class="uk-margin-small-left">Loading</span>
                            </button>
                            <button 
                                v-else
                                class="uk-button uk-button-danger" 
                                type="button"
                                :disabled="form_user_education_button_save_loading"
                                @click="deleteEducation"
                            >Delete</button>
                        </div>
                        <div>
                            <button 
                                v-if="form_user_education_button_save_loading" 
                                class="uk-button uk-button-default uk-margin-small-left" 
                                disabled
                            >
                                <span><div uk-spinner="ratio: 0.5"></div></span>
                                <span class="uk-margin-small-left">Loading</span>
                            </button>
                            <button 
                                v-else 
                                class="uk-button uk-button-primary uk-margin-small-left" 
                                type="submit"
                                :disabled="form_user_education_button_delete_loading"
                            >Save</button>
                        </div>
                    </div>
                </form>
            </modal>
            <!-- End form user education modal -->

            <!-- Form user skill modal -->
            <modal name="form-user-skill-modal" height="auto" styles="max-height: 90%; overflow-y: auto;" :clickToClose="false">
                <button class="uk-modal-close-default" type="button" uk-close @click="$modal.hide('form-user-skill-modal')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Skill</h2>
                </div>
                <form @submit.prevent="saveUserSkill">
                    <div class="uk-grid-small uk-padding" uk-grid>
                        <div class="uk-width-1-1">
                            <label for="skill_name" class="uk-form-label">Skill Name <span class="uk-text-danger">*</span></label>
                            <input
                                v-model="form_user_skill_modal.name"
                                id="skill_name"
                                name="name" 
                                type="text"
                                class="uk-input uk-border-rounded"
                                :class="{'uk-form-danger': errors.has('form_user_skill_modal.name')}"
                                v-validate="'required'"
                                data-vv-rules="required"
                                data-vv-scope="form_user_skill_modal"
                                required
                            >
                            <span class="uk-text-small uk-text-danger" v-show="errors.has('form_user_skill_modal.name')">
                                {{ errors.first('form_user_skill_modal.name') }}
                            </span>
                        </div>
                    </div>
                    <div class="uk-modal-footer uk-text-right">
                        <button 
                            v-if="form_user_skill_button_save_loading" 
                            class="uk-button uk-button-default uk-margin-small-left" 
                            disabled
                        >
                            <span><div uk-spinner="ratio: 0.5"></div></span>
                            <span class="uk-margin-small-left">Loading</span>
                        </button>
                        <button 
                            v-else
                            class="uk-button uk-button-primary" 
                            type="submit"
                        >Save</button>
                    </div>
                </form>
            </modal>
            <!-- End form user skill modal -->

            <!-- Form user required document modal -->
            <modal name="form-user-required-document-modal" height="auto" styles="max-height: 90%; overflow-y: auto;" :clickToClose="false">
                <button class="uk-modal-close-default" type="button" uk-close @click="$modal.hide('form-user-required-document-modal')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Required Documents</h2>
                </div>
                <div class="uk-grid-small uk-padding" uk-grid>
                    <div class="uk-width-1-1 uk-card uk-card-default uk-border-rounded">
                        <div v-if="upload_ktp_loading" class="uk-flex uk-flex-middle uk-flex-center uk-padding-small">
                            <span><div uk-spinner="ratio: 0.5"></div></span>
                            <span class="uk-margin-small-left">Loading...</span>
                        </div>
                        <div v-else class="uk-flex uk-flex-between" uk-form-custom @input="uploadKTP">
                            <input
                              name="KTP"
                              type="file"
                              ref="KTP"
                            >
                            <div class="uk-margin-bottom uk-margin-top uk-margin-left">KTP</div>
                            <div class="uk-margin-bottom uk-margin-top uk-margin-right">
                                <img :src="`${images}/check-grey.svg`" style="width:30px" v-if="user_req_doc.find(o => o.name === 'KTP')">
                                <img :src="`${images}/plus.svg`" style="width:30px" v-else>
                            </div>
                        </div>
                    </div>
                    <div class="uk-width-1-1 uk-card uk-card-default uk-border-rounded">
                        <div v-if="upload_sim_loading" class="uk-flex uk-flex-middle uk-flex-center uk-padding-small">
                            <span><div uk-spinner="ratio: 0.5"></div></span>
                            <span class="uk-margin-small-left">Loading...</span>
                        </div>
                        <div v-else class="uk-flex uk-flex-between" uk-form-custom @input="uploadSIM">
                            <input
                              name="SIM"
                              type="file"
                              ref="SIM"
                            >
                            <div class="uk-margin-bottom uk-margin-top uk-margin-left">SIM</div>
                            <div class="uk-margin-bottom uk-margin-top uk-margin-right">
                                <img :src="`${images}/check-grey.svg`" style="width:30px" v-if="user_req_doc.find(o => o.name === 'SIM')">
                                <img :src="`${images}/plus.svg`" style="width:30px" v-else>
                            </div>
                        </div>
                    </div>
                    <div class="uk-width-1-1 uk-card uk-card-default uk-border-rounded">
                        <div v-if="upload_ijazah_loading" class="uk-flex uk-flex-middle uk-flex-center uk-padding-small">
                            <span><div uk-spinner="ratio: 0.5"></div></span>
                            <span class="uk-margin-small-left">Loading...</span>
                        </div>
                        <div v-else class="uk-flex uk-flex-between" uk-form-custom @input="uploadIjazah">
                            <input
                              name="Ijazah"
                              type="file"
                              ref="Ijazah"
                            >
                            <div class="uk-margin-bottom uk-margin-top uk-margin-left">Ijazah</div>
                            <div class="uk-margin-bottom uk-margin-top uk-margin-right">
                                <img :src="`${images}/check-grey.svg`" style="width:30px" v-if="user_req_doc.find(o => o.name === 'Ijazah')">
                                <img :src="`${images}/plus.svg`" style="width:30px" v-else>
                            </div>
                        </div>
                    </div>
                    <div class="uk-width-1-1 uk-card uk-card-default uk-border-rounded">
                        <div v-if="upload_skck_loading" class="uk-flex uk-flex-middle uk-flex-center uk-padding-small">
                            <span><div uk-spinner="ratio: 0.5"></div></span>
                            <span class="uk-margin-small-left">Loading...</span>
                        </div>
                        <div v-else class="uk-flex uk-flex-between" uk-form-custom @input="uploadSKCK">
                            <input
                              name="SKCK"
                              type="file"
                              ref="SKCK"
                            >
                            <div class="uk-margin-bottom uk-margin-top uk-margin-left">SKCK</div>
                            <div class="uk-margin-bottom uk-margin-top uk-margin-right">
                                <img :src="`${images}/check-grey.svg`" style="width:30px" v-if="user_req_doc.find(o => o.name === 'SKCK')">
                                <img :src="`${images}/plus.svg`" style="width:30px" v-else>
                            </div>
                        </div>
                    </div>
                </div>
            </modal>
            <!-- End form user required document modal -->

            <!-- Action modal -->
            <modal name="action-modal" height="auto" styles="max-height: 90%; overflow-y: auto;" :clickToClose="false">
                <button class="uk-modal-close-default" type="button" uk-close @click="$modal.hide('action-modal')"></button>
                <div class="uk-padding">
                    <p class="uk-margin-remove uk-padding-remove">What do you want to do with this file?</p>
                    <p class="uk-margin-remove uk-padding-remove">{{ doc_detail ? doc_detail.name : '-' }}</p>
                </div>
                <div class="uk-modal-footer uk-flex uk-flex-between uk-flex-middle">
                    <div>
                        <button 
                            v-if="button_delete_other_document_loading"
                            class="uk-button uk-button-default uk-margin-small-left" 
                            disabled
                        >
                            <span><div uk-spinner="ratio: 0.5"></div></span>
                            <span class="uk-margin-small-left">Loading</span>
                        </button>
                        <button 
                            v-else
                            class="uk-button uk-button-danger" 
                            type="button"
                            @click="deleteOtherDocument"
                        >Delete</button>
                    </div>
                    <div v-if="doc_detail">
                        <button v-if="button_delete_other_document_loading" class="uk-button uk-button-default" type="button" disabled>Download</button>
                        <a
                            v-else
                            class="uk-button uk-button-primary" 
                            type="button" 
                            :href="doc_detail.url.file_url"
                        >Download</a>
                    </div>
                </div>
            </modal>
            <!-- End action modal -->
        </div>
        <!-- End page content -->
    </div>
</template>

<script>
import { PREFIX_IMAGE } from '@/utils/constant';
import { mapActions, mapGetters } from 'vuex';
import formatter from "@/utils/formatter";
import moment from 'moment';
import { Datetime } from 'vue-datetime';
import { 
    notificationSuccess, 
    notificationDanger,
    notificationDangerCustom 
} from '@/utils/notification';
import { API_USER } from '@/utils/api-url';
import api from '@/utils/api';

export default {
    name: 'MitraDetail',
    components: {
        Datetime
    },
    data() {
        return {
            is_loading: false,
            images: PREFIX_IMAGE,
            user_data: null,
            user_edu: null,
            user_exp: null,
            user_skill: null,
            user_doc: [],
            user_req_doc: [],
            city_options: [],
            is_fetching: false,
            job_preference_options: [],
            role_options: [
                { 
                    name: 'Candidate', 
                    value: ['zxc123'] 
                }
            ],
            partner_options: [],
            form_is_edit: false,
            form_user_modal: {
                id: '',
                fullname: '',
                login_via_email: true,
                email: '',
                phone_number: '',
                password: '',
                birthplace_city_id: null,
                birthdate: null,
                domicile_city_id: null,
                address: '',
                description: null,
                job_preference_ids: [],
                roles: '',
                partner_ids: [],
                notes_superadmin: '',
                geoloc: null,
                company_id: null,
                client_role_id: null,
                client_office_id: null,
                user_verified: false,
                verification_code: null
            },
            form_user_modal_button_loading: false,
            form_user_experience_modal: {
                sort: 1,
                company_name: '',
                title: '',
                start_date: null,
                end_date: null,
                present: false,
                description: null
            },
            form_user_experience_button_save_loading: false,
            form_user_experience_button_delete_loading: false,
            form_user_education_modal: {
                sort: 1,
                education_level_id: '',
                institution_name: '',
                majors: '',
                start_date: null,
                end_date: null,
                present: false
            },
            form_user_education_button_save_loading: false,
            form_user_education_button_delete_loading: false,
            form_user_skill_modal: {
                sort: 1,
                name: ''
            },
            form_user_skill_button_save_loading: false,
            upload_ktp_loading: false,
            upload_sim_loading: false,
            upload_ijazah_loading: false,
            upload_skck_loading: false,
            doc_detail: null,
            button_add_other_document_loading: false,
            button_delete_other_document_loading: false,
            button_unduh_cv_loading: false,
            passwordErrors: {
                hasUpperCase: false,
                hasLowerCase: false,
                minimalChars: false
            }
        };
    },
    watch: {
        'form_user_modal.password'(nVal){
            const regexUpper = /([A-Z])/g;
            const regexLower = /([a-z])/g;
            const hasUpperCase = regexUpper.test(nVal);
            const hasLowerCase = regexLower.test(nVal);
            const minimalChars = nVal == undefined ? false : nVal.length >= 8 || false ;
            this.passwordErrors.hasUpperCase = hasUpperCase;
            this.passwordErrors.hasLowerCase = hasLowerCase;
            this.passwordErrors.minimalChars = minimalChars;
        }
    },
    computed : {
        ...mapGetters({
            companies: 'company/companies',
            company: 'company/company',
            educations: 'option_data/educations'
        }),
    },
    async mounted() {
        try {
            this.is_loading = true;
            await this.getUserData();
            await this.setJobPreferenceOptions();
            await this.getEducation();
            this.is_loading = false;
        } catch (error) {
            notificationDanger(error);
            this.is_loading = false;
        }
    },
    methods: {
        ...mapActions({
            getUserDetail: 'user/getUserDetail',
            getCity: 'option_data/getCity',
            getCompanies: 'company/getCompanies',
            getCompanyDetail: 'company/getCompanyDetail',
            getJobPreference: 'option_data/getJobPreference',
            updateUser: 'user/updateUser',
            createUser: 'user/createUser',
            createUserWorkExp: 'user/createUserWorkExp',
            updateUserWorkExp: 'user/updateUserWorkExp',
            deleteUserWorkExp: 'user/deleteUserWorkExp',
            getEducation: 'option_data/getEducation',
            createUserEducation: 'user/createUserEducation',
            updateUserEducation: 'user/updateUserEducation',
            deleteUserEducation: 'user/deleteUserEducation',
            createUserSkill: 'user/createUserSkill',
            userUploadOtherDocument: 'user/userUploadOtherDocument',
            userUploadDocument: 'user/userUploadDocument',
            deleteUserOtherDocument: 'user/deleteUserOtherDocument'
        }),
        editUser({ worker }) {
            this.resetFormUserModal();
            this.fillFormUserModal({ worker });
            this.$modal.show('form-user-modal');
        },
        addUserExperience() {
            this.resetFormUserExperienceModal();
            this.$modal.show('form-user-experience-modal');
        },
        editUserExperience({ experience }) {
            this.resetFormUserExperienceModal();
            this.fillFormUserExperienceModal({ experience });
            this.$modal.show('form-user-experience-modal');
        },
        addUserEducation() {
            this.resetFormEducationModal();
            this.$modal.show('form-user-education-modal');
        },
        editUserEducation({ education }) {
            this.resetFormEducationModal();
            this.fillFormEducationModal({ education });
            this.$modal.show('form-user-education-modal');
        },
        addUserSkill() {
            this.resetFormUserSkillModal();
            this.$modal.show('form-user-skill-modal');
        },
        addUserAdditionalDocument() {
            this.$modal.show('form-user-required-document-modal');
        },
        deleteDocument({ document }) {
            this.resetFormActionModal();
            this.fillFormActionModal({ document });
            this.$modal.show('action-modal');
        },
        async saveUser() {
            try {
                const validate = await this.$validator.validateAll();
                if (!validate || this.$validator.errors.any()) return;

                this.form_user_modal_button_loading = true;

                // Set payload
                this.form_user_modal.roles = this.form_user_modal.roles.value;
                this.form_user_modal.company_id = this.form_user_modal.company_id ? this.form_user_modal.company_id.value : null;
                this.form_user_modal.client_role_id = this.form_user_modal.client_role_id ? this.form_user_modal.client_role_id.value : null;
                this.form_user_modal.client_office_id = this.form_user_modal.client_office_id ? this.form_user_modal.client_office_id.value : null;
                this.form_user_modal.domicile_city_id = this.form_user_modal.domicile_city_id ? this.form_user_modal.domicile_city_id._id : null;
                this.form_user_modal.birthplace_city_id = this.form_user_modal.birthplace_city_id ? this.form_user_modal.birthplace_city_id._id : null;
                this.form_user_modal.partner_ids = this.form_user_modal.partner_ids.map(partner => partner.value);
                this.form_user_modal.job_preference_ids = this.form_user_modal.job_preference_ids.map(pref => pref._id);
                if (!this.form_user_modal.password) delete this.form_user_modal.password;
                if (!this.form_user_modal.verification_code) delete this.form_user_modal.verification_code;
                if (!this.form_user_modal.email) this.form_user_modal.email = null;
                if (!this.form_user_modal.phone_number) this.form_user_modal.phone_number = null;
                
                // Update or create user data
                const response = this.form_is_edit ? await this.updateUser(this.form_user_modal) : await this.createUser(this.form_user_modal);
                if (response && response.status === 'OK') {
                    notificationSuccess('Data saved successfully!');
                    await this.getUserData();
                    this.$modal.hide('form-user-modal');
                    this.resetFormUserModal();
                    this.form_user_modal_button_loading = false;
                } else {
                    notificationDangerCustom('Data failed to save!');
                    this.form_user_modal_button_loading = false;
                }
            } catch (error) {
                notificationDanger(error);
                this.form_user_modal_button_loading = false;
            }
        },
        async deleteUserExperience() {
            try {
                this.form_user_experience_button_delete_loading = true;
                const response = await this.deleteUserWorkExp(this.form_user_experience_modal.id);
                if (response && response.status === 'OK' && response.result === 'success') {
                    notificationSuccess('Data deleted successfully!');
                    await this.getUserData();
                    this.$modal.hide('form-user-experience-modal');
                    this.resetFormUserExperienceModal();
                    this.form_user_experience_button_delete_loading = false;
                } else {
                    notificationDangerCustom('Data failed to delete!');
                    this.form_user_experience_button_delete_loading = false;
                }
            } catch (error) {
                notificationDanger(error);
                this.form_user_experience_button_delete_loading = false;
            }
        },
        async saveUserExperience() {
            try {
                const validate = await this.$validator.validate('form_user_experience_modal.*');
                if (!validate || this.$validator.errors.any()) return;

                this.form_user_experience_button_save_loading = true;

                // Set payloads
                if (this.form_user_experience_modal.end_date == '') {
                    this.form_user_experience_modal.end_date = null;
                    this.form_user_experience_modal.present = true;
                } else {
                    this.form_user_experience_modal.present = false;
                }

                let response = null;
                if (this.form_is_edit) {
                    response = await this.updateUserWorkExp(this.form_user_experience_modal);
                } else {
                    this.form_user_experience_modal.user_id = this.user_data._id;
                    response = await this.createUserWorkExp(this.form_user_experience_modal);
                }

                if (response && response.status === 'OK') {
                    notificationSuccess('Data saved successfully!');
                    await this.getUserData();
                    this.$modal.hide('form-user-experience-modal');
                    this.resetFormUserExperienceModal();
                    this.form_user_experience_button_save_loading = false;
                } else {
                    notificationDangerCustom('Data failed to save!');
                    this.form_user_experience_button_save_loading = false;
                }
            } catch (error) {
                notificationDanger(error);
                this.form_user_experience_button_save_loading = false;
            }
        },
        async deleteEducation() {
            try {
                this.form_user_education_button_delete_loading = true;
                const response = await this.deleteUserEducation(this.form_user_education_modal.id);
                if (response && response.status === 'OK' && response.result === 'success') {
                    notificationSuccess('Data deleted successfully!');
                    await this.getUserData();
                    this.$modal.hide('form-user-education-modal');
                    this.resetFormEducationModal();
                    this.form_user_education_button_delete_loading = false;
                } else {
                    notificationDangerCustom('Data failed to delete!');
                    this.form_user_education_button_delete_loading = false;
                }
            } catch (error) {
                notificationDanger(error);
                this.form_user_education_button_delete_loading = false;
            }
        },
        async saveUserEducation() {
            try {
                const validate = await this.$validator.validate('form_user_education_modal.*');
                if (!validate || this.$validator.errors.any()) return;

                this.form_user_education_button_save_loading = true;

                let tmp_edu = this.form_user_education_modal.education_level_id;
                this.form_user_education_modal.education_level_id = tmp_edu._id;
                if (this.form_user_education_modal.end_date == '') {
                    this.form_user_education_modal.end_date = null;
                    this.form_user_education_modal.present = true;
                } else {
                    this.form_user_education_modal.present = false;
                }

                let response = null;
                if (this.form_is_edit) {
                    response = await this.updateUserEducation(this.form_user_education_modal);
                } else {
                    this.form_user_education_modal.user_id = this.user_data._id;
                    response = await this.createUserEducation(this.form_user_education_modal);
                }

                if (response && response.status === 'OK') {
                    notificationSuccess('Data saved successfully!');
                    await this.getUserData();
                    this.$modal.hide('form-user-education-modal');
                    this.resetFormEducationModal();
                    this.form_user_education_button_save_loading = false;
                } else {
                    this.form_user_education_modal.education_level_id = tmp_edu;
                    notificationDangerCustom('Data failed to save!');
                    this.form_user_education_button_save_loading = false;
                }
            } catch (error) {
                notificationDanger(error);
                this.form_user_education_button_save_loading = false;
            }
        },
        async saveUserSkill() {
            try {
                const validate = await this.$validator.validate('form_user_skill_modal.*');
                if (!validate || this.$validator.errors.any()) return;

                this.form_user_skill_button_save_loading = true;

                this.form_user_skill_modal.user_id = this.user_data._id;

                const response = await this.createUserSkill(this.form_user_skill_modal);
                if (response && response.status === 'OK') {
                    notificationSuccess('Data saved successfully!');
                    await this.getUserData();
                    this.$modal.hide('form-user-skill-modal');
                    this.resetFormUserSkillModal();
                    this.form_user_skill_button_save_loading = false;
                } else {
                    notificationDangerCustom('Data failed to save!');
                    this.form_user_skill_button_save_loading = false;
                }
                
            } catch (error) {
                notificationDanger(error);
                this.form_user_skill_button_save_loading = false;
            }
        },
        async uploadOtherDocument() {
            try {
                this.button_add_other_document_loading = true;
                let files = this.$refs.other_document.files;
                let formData = new FormData();

                for (let i = 0; i < files.length; i++) {
                    let file = files[i];
                    formData.set('file', file, file.fileName);
                    const response = await this.userUploadOtherDocument({ folder: 'cv-other-document', user_id: this.user_data._id, data: formData });
                    if (response && response.status === 'OK') {
                        notificationSuccess('Data uploaded successfully!');
                        this.button_add_other_document_loading = false;
                        await this.getUserData();
                    } else {
                        notificationDangerCustom('Data failed to upload!');
                        this.button_add_other_document_loading = false;
                    }
                }
            } catch (error) {
                notificationDanger(error);
                this.button_add_other_document_loading = false;
            }
        },
        async uploadKTP() {
            try {
                this.upload_ktp_loading = true;
                let files = this.$refs.KTP.files;
                let formData = new FormData();

                for (let i = 0; i < files.length; i++) {
                    let file = files[i];
                    formData.set('file', file, file.fileName);
                    const response = await this.userUploadDocument({ folder: 'cv-other-document', type: 'KTP', user_id: this.user_data._id, data: formData });
                    if (response && response.status === 'OK') {
                        notificationSuccess('KTP upload successful!');
                        this.upload_ktp_loading = false;
                        await this.getUserData();
                    } else {
                        notificationDangerCustom('Failed to upload KTP!');
                        this.upload_ktp_loading = false;
                    }
                }
            } catch (error) {
                notificationDanger(error);
                this.upload_ktp_loading = false;
            }
        },
        async uploadSIM() {
            try {
                this.upload_sim_loading = true;
                let files = this.$refs.SIM.files;
                let formData = new FormData();

                for (let i = 0; i < files.length; i++) {
                    let file = files[i];
                    formData.set('file', file, file.fileName);
                    const response = await this.userUploadDocument({ folder: 'cv-other-document', type: 'SIM', user_id: this.user_data._id, data: formData });
                    if (response && response.status === 'OK') {
                        notificationSuccess('SIM upload successful!');
                        this.upload_sim_loading = false;
                        await this.getUserData();
                    } else {
                        notificationDangerCustom('Failed to upload SIM!');
                        this.upload_sim_loading = false;
                    }
                }
            } catch (error) {
                notificationDanger(error);
                this.upload_sim_loading = false;
            }
        },
        async uploadIjazah() {
            try {
                this.upload_ijazah_loading = true;
                let files = this.$refs.Ijazah.files;
                let formData = new FormData();

                for (let i = 0; i < files.length; i++) {
                    let file = files[i];
                    formData.set('file', file, file.fileName);
                    const response = await this.userUploadDocument({ folder: 'cv-other-document', type: 'Ijazah', user_id: this.user_data._id, data: formData });
                    if (response && response.status === 'OK') {
                        notificationSuccess('Ijazah upload successful!');
                        this.upload_ijazah_loading = false;
                        await this.getUserData();
                    } else {
                        notificationDangerCustom('Failed to upload Ijazah!');
                        this.upload_ijazah_loading = false;
                    }
                }
            } catch (error) {
                notificationDanger(error);
                this.upload_ijazah_loading = false;
            }
        },
        async uploadSKCK() {
            try {
                this.upload_skck_loading = true;
                let files = this.$refs.SKCK.files;
                let formData = new FormData();

                for (let i = 0; i < files.length; i++) {
                    let file = files[i];
                    formData.set('file', file, file.fileName);
                    const response = await this.userUploadDocument({ folder: 'cv-other-document', type: 'SKCK', user_id: this.user_data._id, data: formData });
                    if (response && response.status === 'OK') {
                        notificationSuccess('SKCK upload successful!');
                        this.upload_skck_loading = false;
                        await this.getUserData();
                    } else {
                        notificationDangerCustom('Failed to upload SKCK!');
                        this.upload_skck_loading = false;
                    }
                }
            } catch (error) {
                notificationDanger(error);
                this.upload_skck_loading = false;
            }
        },
        async deleteOtherDocument(){
            try {
                this.button_delete_other_document_loading = true;
                const response = await this.deleteUserOtherDocument(this.doc_detail._id);
                if (response && response.status === 'OK') {
                    notificationSuccess('Data deleted successfully!');
                    this.button_delete_other_document_loading = false;
                    this.$modal.hide('action-modal');
                    await this.getUserData();
                } else {
                    notificationDangerCustom('Data failed to delete!');
                    this.button_delete_other_document_loading = false;
                }
            } catch (error) {
                notificationDanger(error);
                this.button_delete_other_document_loading = false;
            }
        },
        async downloadCV() {
            try {
                this.button_unduh_cv_loading = true;
                const result = await api.apiGetAuth(API_USER.GET_CV(this.user_data._id));
                notificationSuccess('CV successfully downloaded!');
                this.button_unduh_cv_loading = false;
                window.open(result.data.result.cv_path.file_url);
            } catch (err) {
                notificationDanger(err);
                this.button_unduh_cv_loading = false;
            }
        },
        async getUserData() {
            this.user_doc = [];
            this.user_req_doc = [];
            this.user_data = await this.getUserDetail(this.$route.params.user_id);
            if (this.user_data && this.user_data.cv) {
                if (this.user_data.cv.ktp) {
                    this.user_req_doc.push({ name: 'KTP', url: this.user_data.cv.ktp.document_url });
                }
                if (this.user_data.cv.sim) {
                    this.user_req_doc.push({ name: 'SIM', url: this.user_data.cv.sim.document_url });
                }
                if (this.user_data.cv.ijazah) {
                    this.user_req_doc.push({ name: 'Ijazah', url: this.user_data.cv.ijazah.document_url });
                }
                if (this.user_data.cv.skck) {
                    this.user_req_doc.push({ name: 'SKCK', url: this.user_data.cv.skck.document_url });
                }
                if (this.user_data.cv.other_documents) {
                    for (const doc of this.user_data.cv.other_documents) {
                        this.user_doc.push({ _id: doc._id, name: doc.name, url: doc.document_url });
                    }
                }
            }
            this.user_data = this.user_data.user;
            this.user_exp = this.user_data.cv ? this.user_data.cv.work_experiences ? this.user_data.cv.work_experiences : null : null;
            this.user_edu = this.user_data.cv ? this.user_data.cv.educations ? this.user_data.cv.educations : null : null;
            this.user_skill = this.user_data.cv ? this.user_data.cv.skills ? this.user_data.cv.skills : null : null;
        },
        async setCityValues(query) {
            try {
                this.is_fetching = true;
                this.city_options = await this.getCity({ name: query });
                this.is_fetching = false;
            } catch (error) {
                this.is_fetching = false;
            }
        },
        async setPartnerValues(query) {
            try {
                this.is_fetching = true;
                await this.getCompanies({name: query});
                this.partner_options = this.companies.docs.map((obj) => ({ name: obj.name, value: obj._id }));
                this.is_fetching = false;
            } catch (error) {
                this.is_fetching = false;
            }
        },
        async setJobPreferenceOptions() {
            this.job_preference_options = await this.getJobPreference();
        },
        async fillFormUserModal({ worker }) {
            try {
                this.form_is_edit = true;
                this.form_user_modal.id = worker._id;
                this.form_user_modal.fullname = worker.fullname;
                this.form_user_modal.login_via_email = worker.verification_code ? worker.verification_code.toString().length === 6 : true;
                this.form_user_modal.email = worker.email;
                this.form_user_modal.phone_number = worker.phone_number;
                this.form_user_modal.password = '';
                this.form_user_modal.birthplace_city_id = worker.birthplace_city ? { 
                    _id: worker.birthplace_city?._id, 
                    name: worker.birthplace_city?.name, 
                    province: worker.birthplace_city?.province 
                } : null;
                this.form_user_modal.birthdate = worker.birthdate;
                this.form_user_modal.domicile_city_id = worker.domicile_city ? { 
                    _id: worker.domicile_city?._id, 
                    name: worker.domicile_city?.name, 
                    province: worker.domicile_city?.province 
                } : null;
                this.form_user_modal.address = worker.address;
                this.form_user_modal.description = worker.description;
                this.form_user_modal.job_preference_ids = worker.job_preference_ids;
                this.form_user_modal.roles = this.role_options.find((e) => e.value[0] === worker.roles);
                this.form_user_modal.notes_superadmin = worker.notes_superadmin;
                this.form_user_modal.geoloc = worker.geoloc;
                this.form_user_modal.user_verified = worker.user_verified;
                this.form_user_modal.verification_code = worker.verification_code;
                this.form_user_modal.company_id = worker.company ? { 
                    name: worker.company?.name, 
                    value: worker.company?._id 
                } : null;
                this.form_user_modal.client_role_id = worker.client_role ? { 
                    name: worker.client_role?.name, 
                    value: worker.client_role?._id 
                } : null;
                this.form_user_modal.client_office_id = worker.client_office ? { 
                    name: worker.client_office?.name, 
                    value: worker.client_office?._id 
                } : null;

                if (worker.partner_ids.length > 0) {
                    worker.partner_ids.forEach(async partner_id => {
                        await this.getCompanyDetail(partner_id);
                        this.form_user_modal.partner_ids.push({ name: this.company.name, value: this.company._id });
                    });
                } else {
                    this.form_user_modal.partner_ids = [];
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        fillFormUserExperienceModal({ experience }) {
            this.form_is_edit = true;
            this.form_user_experience_modal.id = experience._id;
            this.form_user_experience_modal.sort = experience.sort;
            this.form_user_experience_modal.company_name = experience.company_name;
            this.form_user_experience_modal.title = experience.title;
            this.form_user_experience_modal.start_date = experience.start_date;
            this.form_user_experience_modal.end_date = experience.end_date;
            this.form_user_experience_modal.present = experience.present;
            this.form_user_experience_modal.description = experience.description;
        },
        fillFormEducationModal({ education }) {
            this.form_is_edit = true;
            this.form_user_education_modal.id = education._id;
            this.form_user_education_modal.sort = education.sort;
            this.form_user_education_modal.education_level_id = this.educations.find(e => e._id === education.education_level_id._id);
            this.form_user_education_modal.institution_name = education.institution_name;
            this.form_user_education_modal.majors = education.majors;
            this.form_user_education_modal.start_date = education.start_date;
            this.form_user_education_modal.end_date = education.end_date;
            this.form_user_education_modal.present = education.present;
        },
        fillFormActionModal({ document }) {
            this.doc_detail = document;
        },
        resetFormUserModal() {
            this.form_is_edit = false;
            this.form_user_modal = {
                id: '',
                fullname: '',
                login_via_email: true,
                email: '',
                phone_number: '',
                password: '',
                birthplace_city_id: null,
                birthdate: null,
                domicile_city_id: null,
                address: '',
                description: null,
                job_preference_ids: [],
                roles: '',
                partner_ids: [],
                notes_superadmin: '',
                geoloc: null,
                company_id: null,
                client_role_id: null,
                client_office_id: null,
                user_verified: false,
                verification_code: null
            };
            this.$validator.reset();
        },
        resetFormUserExperienceModal() {
            this.form_is_edit = false;
            this.form_user_experience_modal = {
                sort: 1,
                company_name: '',
                title: '',
                start_date: null,
                end_date: null,
                present: false,
                description: null
            };
            this.$validator.reset();
        },
        resetFormEducationModal() {
            this.form_is_edit = false;
            this.form_user_education_modal = {
                sort: 1,
                education_level_id: '',
                institution_name: '',
                majors: '',
                start_date: null,
                end_date: null,
                present: false
            };
            this.$validator.reset();
        },
        resetFormUserSkillModal() {
            this.form_is_edit = false;
            this.form_user_skill_modal = {
                sort: 1,
                name: ''
            };
            this.$validator.reset();
        },
        resetFormActionModal() {
            this.doc_detail = null;
        },
        imgError({ event }){
            event.target.src = `${this.images}/avatar.svg`;
        },
        dobUser({ user }){
            let text = '';
            if (user.birthplace_city) {
                text += user.birthplace_city.name + ', ';
            } else {
                text += '-,';
            }

            if (user.birthdate) {
                text += formatter.dateComplete(user.birthdate);
            } else {
                text += '-';
            }
            return text;
        },
        showJobPref({ data }){
            let text = '';
            if (data.length > 0) {
                for (let key = 0; key < data.length; key++) {
                    if (data[key+1]) {
                        text += data[key].name + ', ';
                    } else {
                        text += data[key].name;
                    }
                }
            } else {
                text = '-';
            }
            return text;
        },
        getYear({ date }){
            if (date) {
                return moment(date).format('YYYY');
            } else {
                return '-';
            }
        },
        skillList({ skills }){
            let text = '';
            if (skills.length > 0) {
                for (let key = 0; key < skills.length; key++) {
                    if (skills[key+1]) {
                        text += skills[key].skill_category.name + ', ';
                    } else {
                        text += skills[key].skill_category.name;
                    }
                }
            } else {
                text = '-';
            }
            return text;
        }
    }
};
</script>

<style scoped>
    .user-photo {
        width: 120px;
        height: 120px;
        position: relative;
        overflow: hidden;
        border-radius: 50%;
    }
</style>
